import React from 'react';
import { Typography } from '@material-ui/core';
import { useRouter } from 'next/router';
import Fields from 'components/CreateOrder/CreateOrderFormFields';
import { useCartGetter } from 'contexts/CartContext';

const CreateOrderForm = (props) => {
  // value form control
  const { handleSubmit } = props;
  const cart = useCartGetter();
  const { query } = useRouter();
  return (
    <form onSubmit={handleSubmit} id='create-order-form' className='create-order-form'>
      <Typography variant='h1'>
        {'Step 1 of 3'}
        {cart.items.length > 0 && ` - Item #${query?.id === undefined ? cart.items.length + 1 : parseInt(query.id) + 1}`}
      </Typography>
      <Fields cart={cart} {...props} />
    </form>
  );
};

export default CreateOrderForm;
