import { format } from 'date-fns';

/**
 * formateDate
 *
 * @param date
 */
export const FormatDate = (date) => {
  const d = new Date(date);
  return format(new Date(d.getFullYear(), d.getMonth(), d.getDate()), 'MM/dd/yyyy');
};

export const GetMostRecentDate = (repairsArray) => {
  if (repairsArray.length > 1) {
    return repairsArray.reduce((a, b) => {
      const aDate = a.lastModified === null ? a.created : a.lastModified;
      const bDate = b.lastModified === null ? b.created : b.lastModified;
      const dateCheck = new Date(aDate) > new Date(bDate) ? aDate : bDate;
      return FormatDate(dateCheck);
    });
  }
  const item = repairsArray[0];
  const date = item.DatelastModified === null ? item.created : item.lastModified;
  return FormatDate(date);
};
